import React, { useState } from "react"
import Layout from "../components/Layout"

import { textPage, success } from "../styles/textpage.module.css"

export default function CookiePolicy() {
  const [isCookieReset, setIsCookieReset] = useState(false)

  const resetCookie = () => {
    document.cookie = "sk-gdpr-google-analytics =; Max-Age=0"
    setIsCookieReset(true)
  }
  return (
    <Layout>
      <article className={`${textPage} container`}>
        <h1>STUDIO KATHAN COOKIE POLICE NOTICE</h1>

        <p>
          Studio KATHAN uses “cookies” whenever you use the Website. In this
          cookie statement, we would like to inform you about our use of
          cookies. We emphasize that certain functionalities of the Website will
          not be available when you do not accept the cookies involved. Our use
          of cookies is subject to this cookie statement. Given that cookies may
          contain personal data, their processing is also governed by our
          privacy statement.
          <br />
          For questions and/or comments, please contact hello@studiokathan.com.
        </p>

        <h2>About cookies</h2>
        <p>
          A “cookie” is a piece of information sent to your device via the
          server of the Website and stored on the device’s hard drive when you
          visit the Website. Cookies help us to recognize your device when you
          use the Website. The Website's server can only read the cookies it has
          placed itself and has no access to other files stored on your device.
          This enables Studio KATHAN to make processing user-friendly and to
          provide you with a personalized service. Cookies also include
          so-called ‘scripts’. A script is a programming code used in order to
          make the Website function optimally and interactively. Finally,
          cookies also include ‘webbeacons’, ‘pixeltags’ or ‘social media
          cookies’. These cookies are used for the integration of social media
          websites in the Website.
        </p>

        <h2>Types of cookies</h2>
        <p>There are different kind of cookies:</p>

        <h3>Origin</h3>
        <p>
          First party cookies are cookies that are placed directly on the
          Website by us. Third party cookies are cookies placed on the Website
          by third parties. This means that elements originating from other
          websites are incorporated in the Website.
        </p>

        <h3>Lifetime</h3>
        <p>
          Persistent cookies remain on your device for the lifetime of the
          cookie. They are activated each time you visit the Website. Session
          cookies simplify and link the user’s actions during the browser
          session. Each time you open the browser, these cookies are placed
          temporarily. Session cookies are deleted as soon as the browser is
          closed.
        </p>

        <h3>Functional/purpose</h3>
        <p>
          <u>Functional cookies</u> <br /> 
	  Ensure that all components of the Website function
          optimally. These cookies can be installed without your consent. These
          cookies allow you, for example, to navigate between different parts of
          the Website and to use certain functionalities. These cookies do not
          make it possible to identify you as an individual. If you do not
          accept these cookies, certain parts of the Website may not function or
          may not function optimally.<br /> 

	  <u>Non-functional cookies</u> <br /> 
	  Are installed for statistical, social and marketing purposes. Your explicit consent is
          required to install these cookies.<br /> 

	  <u>Analytical cookies</u> <br />  
	  display the way you use the Website. Based on this collected information a statistical
          analysis is made in order to make the structure, navigation and
          content of the Website user-friendly and to improve it. Marketing
          cookies are used to personalize online marketing offers. Based on a
          profile, drawn up on the basis of you surfing behavior on the Website,
          both we and third parties gain insight into campaign performance. If
          social media websites install social media cookies through the Website
          to collect (your) data, studio KATHAN have no influence on the use of
          and the way in which the social media websites handle this data. More
          information in this respect can be found in the privacy statements of
          the social media websi- te, which are each indicated in the list of
          active cookies in article 5 hereunder. Please note that these privacy
          statements may be modified at any time by the social media websites
          involved.
        </p>

        <h2>Legal grounds</h2>
        <p>
          The processing of functional cookies is based on the studio legitimate
          interest as functional cookies are required for the proper functioning
          and security of the Website. The Processing of other cookies is based
          on your prior consent to do so.
        </p>

        <h2>What cookies are used on the website?</h2>

        <p>
          <u>Performance cookies</u> <br />
	  These cookies allow us to count visits and traffic so we can collect
          insights like which pages are the most popular and see how visitors
          move around the site. All information these cookies collect is aggre-
          gated and therefore, anonymous. If you do not allow these cookies, we
          will not be able to provide you with a tailored experience.
        </p>

        <h2>Authorization</h2>
        <p>
          It is only permitted to store cookies on your device without your
          permission if it is impossible to visit the Website without using the
          cookie or if the cookie is necessary for the service provided by the
          Website. If these conditions are not met, we will only place the
          cookies in question after we have obtained your permission. Consent is
          given by clicking on the button “All cookies allowed” to accept all
          cookies when you visit the Website for the first time. Furthermore,
          you have the possibility to set your preferences by clicking the
          button “Set preferences”, where you can choose to use only certain
          cookies.
        </p>

        <h2>Turn on/off</h2>
        <p>
          In the footer of the Website you can delete cookies automatically or
          manually and you can specify whether or not certain cookies may be
          placed.
        </p>
        <p>
          If all cookies are disabled, the Website may not function or not
          function optimally.
        </p>

        <p>
          {!isCookieReset && (
            <a href onClick={resetCookie}>
              Reset cookie
            </a>
          )}
          {isCookieReset && (
            <strong className={success}>Cookie has been reset</strong>
          )}
        </p>
      </article>
    </Layout>
  )
}
